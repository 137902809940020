<template>
  <v-app>
    <v-navigation-drawer
      dark
      v-model="drawer"
      app
      disable-resize-watcher
      v-if="!busy"
    >
      <v-layout column fill-height>
        <!-- <v-list dense>
          <v-list-item to="/">
            <v-list-item-action>
              <v-icon>mdi-home-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Home</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/games/bongomillions/buy">
            <v-list-item-action>
              <v-icon>mdi-basket-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Buy Now</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to>
            <v-list-item-action>
              <v-icon>mdi-chart-bar-stacked</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Winning Numbers</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/login">
            <v-list-item-action>
              <v-icon>mdi-store</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Sign In</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/register">
            <v-list-item-action>
              <v-icon>mdi-store</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Register</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list> -->
        <v-spacer></v-spacer>

        <!-- <div class="overline mx-4 grey--text">User</div>
        <v-list dense class="py-0">
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-account-key-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content class="body-2">uuuu</v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content>
              <v-btn outlined rounded @click="logout">Logout</v-btn>
            </v-list-item-content>
          </v-list-item>
        </v-list>-->
      </v-layout>
    </v-navigation-drawer>
    <v-app-bar hide-on-scroll app color="white" v-if="!busy">
      <!-- <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="d-flex d-sm-none"
      /> -->
      <div class="d-flex align-center">
        <a href="/" class="noLink">
          <v-row no-gutters>
            <v-img
              alt="Bongo Lotto Logo"
              class="shrink mr-2"
              contain
              min-width="45"
              src="/img/logo.jpeg"
              transition="scale-transition"
              width="45"
            />
            <div class="mt-1">
              <div class="title blue-grey--text">DESTINY LIFE</div>
              <div class="body-1 mt-n2 light-blue--text text--darken-3">
                Redemption Chapel
              </div>
            </div>
          </v-row>
        </a>
      </div>
      <v-spacer></v-spacer>
      <!-- <div class="d-flex">
        <v-btn to text class="d-none d-sm-flex">
          <span class="mr-2">Link 1</span>
        </v-btn>
      </div>
      <div class="d-flex">
        <v-btn to text class="d-none d-sm-flex">
          <span class="mr-2">Link 2</span>
        </v-btn>
      </div>
      <div class="d-flex">
        <v-btn to text class="d-none d-sm-flex">
          <span class="mr-2">Link 2</span>
        </v-btn>
      </div> -->
    </v-app-bar>

    <v-main class="grey lighten-5">
      <v-container
        class="primary justify-center align-center"
        fluid
        fill-height
        v-if="busy"
      >
        <v-progress-circular
          :size="70"
          :width="7"
          color="white"
          indeterminate
        ></v-progress-circular>
      </v-container>
      <router-view
        style="min-height: calc(100vh - 150px)"
        v-if="!busy"
      ></router-view>
      <Footer v-if="!busy && ($route.path !== '/' || isAuthenticated)" />
      <div class="text-center ma-2">
        <v-snackbar
          v-model="snackState"
          :color="snackBarColor"
          class="center"
          :timeout="timeout"
        >
          <div style="width: 100%; text-align: center">{{ snackBarText }}</div>
        </v-snackbar>
      </div>
    </v-main>
  </v-app>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
// import firebase from "firebase/app";
// import "firebase/auth";
import Footer from "@/components/footer.vue";

export default {
  name: "App",
  components: {
    Footer,
  },
  methods: {
    ...mapMutations(["setSnackbar"]),
    ...mapActions(["getData", "showMessage"]),
    routeChange: function () {},
    goToRoute: function (path) {
      this.menu = false;
      this.$router.push({ path: path });
    },
  },
  watch: {
    // call again the method if the route changes
    $route: "routeChange",
  },

  computed: {
    ...mapState([
      "snackbar",
      "snackBarText",
      "snackBarColor",
      "isAuthenticated",
      "user",
    ]),
    ...mapGetters(["getWalletBalance"]),
    // ...mapGetters([]),
    snackState: {
      get() {
        return this.snackbar;
      },
      set(val) {
        this.setSnackbar(val);
      },
    },
  },
  created: function () {
    setTimeout(async () => {
      try {
        await this.getData(false);
      } catch (error) {
        console.error(error);
        this.showMessage({ error: true, text: "Something went wrong!" });
      }
    }, 10);
    // firebase.auth().onAuthStateChanged(async (user) => {
    //   console.log(
    //     "Firebase User===>>",
    //     user || "not logged in",
    //     this.$router.history.current.name
    //   );
    //   try {
    //     this.menu = false;
    //     if (user) {
    //       await this.getData(true);
    //       this.login(user);
    //       const continueUrl = this.$router.history.current.query.continue;
    //       if (continueUrl) {
    //         this.$router.replace({ path: continueUrl });
    //       } else if (
    //         this.$router.history.current.name === "Login" ||
    //         this.$router.history.current.name === "Register"
    //       ) {
    //         this.$router.replace({ name: "Home" });
    //       }
    //       setTimeout(() => {
    //         this.busy = false;
    //       }, 200);
    //     } else {
    //       await this.getData(false);
    //       this.busy = false;
    //     }
    //   } catch (error) {
    //     this.showMessage({ error: true, text: "Something went wrong!" });
    //     this.busy = false;
    //     //TODO show error page with reload
    //   }
    // });
  },
  data() {
    return {
      showFooter: true,
      menu: false,
      drawer: false,
      timeout: 3000,
      busy: false,
      buyMenu: [
        { title: "Bongo Millions", route: "/games/bongomillions/buy" },
        { title: "Bongo Ball", route: "/games/bongoball/buy" },
      ],
    };
  },
};
</script>

<style lang="css" scoped>
.d-name {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #555555;
}
.noLink {
  color: #444;
  text-decoration: none;
}
</style>
<style lang="css">
.v-slide-group__content {
  background-color: #fafafa;
}
</style>