<template>
  <section class="home">
    <section class="centerBg" v-if="!isAuthenticated">
      <v-row class="justify-center px-4">
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-card :loading="loading" class="mx-auto my-12" max-width="500">
            <template slot="progress">
              <v-progress-linear
                color="deep-purple"
                height="10"
                indeterminate
              ></v-progress-linear>
            </template>
            <v-img
              class="white--text align-end"
              height="250"
              src="/img/prg.jpeg"
            >
              <v-card-title class="display-2 scrim">Events</v-card-title></v-img
            >
            <v-card-text v-if="events">
              <v-card class="mx-auto" max-width="500" flat>
                <div v-if="events.selected">
                  <v-list-item three-line v-if="events.selected">
                    <v-list-item-content>
                      <div class="captionß mb-4">
                        {{ events.selected.days }}
                      </div>
                      <div class="title mb-3">
                        {{ events.selected.title }}
                      </div>
                      <v-list-item-subtitle>{{
                        events.selected.subtitle
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <img width="90" :src="events.selected.thumbUrl" />
                  </v-list-item>
                  <v-card-actions>
                    <v-btn
                      :to="`/event-register/${events.selected._id}`"
                      rounded
                      class="pink white--text px-6 ml-2"
                    >
                      Register Now
                    </v-btn>
                  </v-card-actions>
                  <v-divider
                    v-if="events.other.length"
                    class="mx-4 mt-2"
                  ></v-divider>
                </div>
                <v-list>
                  <v-list-item
                    two-line
                    v-for="event in events.other"
                    :key="event._id"
                    :to="`/event-register/${event._id}`"
                  >
                    <img width="70" :src="event.thumbUrl" />
                    <v-list-item-content class="ml-4">
                      <div class="caption mb-1">
                        {{ event.days }}
                      </div>
                      <v-list-item-title class="subtitle-1 mb-2">
                        {{ event.title }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-card-text>
            <v-card-actions
              class="ml-4 mt-n4"
              v-if="events && events.other.length"
            >
              <!-- <v-btn color="deep-purple lighten-2" text> View All </v-btn> -->
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <Footer v-if="!isAuthenticated" />
    </section>
  </section>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

import Footer from "@/components/footer.vue";
import { mapState } from "vuex";
export default {
  name: "Home",
  components: { Footer },

  computed: {
    ...mapState(["isAuthenticated", "user", "loading", "events"]),
  },
  data: () => ({
    model: 0,
    carousel_items: [
      {
        img: "c1",
      },
      {
        img: "c2",
      },
      {
        img: "c3",
      },
      {
        img: "c4",
      },
    ],
  }),
};
</script>

<style lang="css" scoped>
.hero {
  position: absolute;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 0;
}
.scrim {
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
}

.help-item {
  width: 340px;
  height: 245px;
  background: #f2f2f2;
  border-radius: 33px;
  padding: 20px;
  margin-bottom: 30px;
}
.help-item-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 45px;
  color: #000000;
}
.help-item-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  color: #000000;
}

@media screen and (max-width: 500px) {
  .centerBg {
    top: 170px;
  }
}
@media screen and (max-width: 360px) {
  .centerBg {
    top: 140px;
  }
}
</style>