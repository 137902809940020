const config = {
    siteUrl: "https://api.destinyliferc.org",
    socketUrl: "https://api.destinyliferc.org",
    errors: {
        generic: 'Something went wrong, please try later',
        noBalance: 'Insufficient balance',
        invalidPhone: 'Phone number is invalid',
        invalidPhoneCode: 'Code provided is invalid',

    },
    games: {
        bongomillions: {
            name: "bongomillions",
            savePath: 'millions_buy_data'
        },
        bongoball: {
            name: "bongoball",
            savePath: 'ball_buy_data'
        }
    }
}
module.exports = config;