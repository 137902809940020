import Vue from 'vue'
import Vuex from 'vuex'
import api from './modules/api';
import firebase from 'firebase/app';
import 'firebase/auth';
import { format } from 'date-fns'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
    user: {},
    events: null,
    isAuthenticated: false,
    snackbar: false,
    snackBarText: "",
    snackBarColor: "purple",
    account: {},
    currency: {},
    country: {},
  },
  getters: {
    getUserId: (state) => {
      return state.user.uid;
    },
    formatDateMed: () => (date) => {
      return date ? format(new Date(date), "MMM dd, yyyy") : ' - ';
    },
    formatTime: () => (date) => {
      return date ? format(new Date(date), "h:mm:ss b") : ' - ';
    },
    formatDateLong: () => (date) => {
      return date ? format(new Date(date), "EEEE, MMM dd, yyyy") : ' - ';
    },
    formatDayName: () => (date) => {
      return date ? format(new Date(date), "EEEE") : ' - ';
    },
    formartCurrencyDecimal: (state) => (n, c, symbol) => {
      c = c ? c : 2;
      let d = d === undefined ? '.' : d;
      let t = t === undefined ? ',' : t;
      let s = n < 0 ? '-' : '';
      let i = parseInt(n = Math.abs(+n || 0).toFixed(2)) + '';
      let j = (j = i.length) > 3 ? j % 3 : 0;
      let val = s + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) + (c ? d + Math.abs(
        n - i).toFixed(c).slice(2) : '');
      return symbol ? state.currency.symbol + val : val;
    },
    formartCurrency: (state) => (n, c, symbol) => {
      c = c ? c : 2;
      let d = d === undefined ? '.' : d;
      let t = t === undefined ? ',' : t;
      let s = n < 0 ? '-' : '';
      let i = parseInt(n = Math.abs(+n || 0).toFixed(2)) + '';
      let j = (j = i.length) > 3 ? j % 3 : 0;
      let val = s + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t);
      return symbol ? state.currency.symbol + val : val;
    },
  },
  mutations: {
    setSnackbar(state, payload) {
      state.snackbar = payload;
    },
    setSnackBarText(state, payload) {
      state.snackBarText = payload;
    },
    setSnackBarColor(state, payload) {
      state.snackBarColor = payload;
    },
    setGames(state, payload) {
      state.games = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setEvents(state, payload) {
      state.events = payload;
    },
    setCountry(state, payload) {
      state.country = payload;
    },
    setBusy(state, val) {
      state.loading = val;
    },
    setUser(state, user) {
      state.user = user;
      if (user && user.uid) {
        state.isAuthenticated = true;
      } else {
        state.isAuthenticated = false;
      }
    },
  },
  actions: {
    logout: async ({ commit }) => {
      await firebase.auth().signOut();
      commit('setUser', {});
      window.location.href = "/";
      // dispatch('stopSocket');
    },
    login: ({ commit }, user) => {
      commit('setUser', user);
    },
    sendChangePasswordEmail: async (context, email) => {
      await firebase.auth().sendPasswordResetEmail(email);
    },
    verifyPassword: async (context, oldPassword) => {
      await firebase.auth().currentUser.reauthenticateWithCredential(firebase.auth.EmailAuthProvider.credential(firebase.auth().currentUser.email, oldPassword));
    },
    showMessage: ({ commit }, options) => {
      commit('setSnackBarText', options.text);
      commit('setSnackBarColor', options.error ? 'deep-orange' : 'info');
      commit('setSnackbar', true);
    },
    registerForEvent: async ({ dispatch, commit }, data) => {
      let params = await dispatch('httpPost', { withAuth: true, url: '/event_register', data: data }, { root: true });
      // console.log("----great--", params.data);
      commit('setBankAccount', params.data);
      return JSON.parse(JSON.stringify(params.data));
    },
    getData: async ({ dispatch, commit }, auth) => {
      try {
        commit("setLoading", true);
        let data = await dispatch('httpGet', { withAuth: auth ? true : false, url: '/get_data' });
        console.log("====>>>got", data.data);
        commit('setEvents', data.data);
        commit("setLoading", false);
      } catch (error) {
        commit("setLoading", false);
        throw error;
      }

      //use default settings currency if user account not present? not logged in
    }
  },
  modules: {
    api: api,
  }
})
