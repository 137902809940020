import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/success',
    name: 'Success',
    component: () => import(/* webpackChunkName: "Success" */ '../views/thank.vue')
  },
  {
    path: '/event-register/:event',
    props: true,
    name: 'EventRegister',
    component: () => {
      return import(/* webpackChunkName: "EventRegister" */ '../views/EventRegister.vue');
    }
  },
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: () => {
  //     return import(/* webpackChunkName: "login" */ '../views/auth/login.vue');
  //   }
  // },
  // {
  //   path: '/verify-email',
  //   name: 'VerifyEmail',
  //   component: () => {
  //     return import(/* webpackChunkName: "VerifyEmail" */ '../views/auth/verifyEmail.vue');
  //   },
  // },


  {
    path: "*",
    props: true,
    name: 'NotFound',
    component: () => {
      return import(/* webpackChunkName: "404" */ '../views/404.vue');
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    console.log(to, from, savedPosition);
    // if (savedPosition) {
    //   return { savedPosition}
    // } else {
    return { x: 0, y: 0 }
    // }
  }
})

export default router
